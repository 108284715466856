import React from "react";
import { AsidePanel, ProductGroupPanel } from "./components";

const GpsrDocuments = () => (
  <div className="main-wrapper">
    <AsidePanel />
    <ProductGroupPanel />
  </div>
);

export default GpsrDocuments;
