import React, { useState } from "react";
import { connect } from "react-redux";
import { ModalUpload, Select } from "expano-components";
import {
  updateProductGroup,
  uploadProductGroupDocument,
} from "actions/product_group";
import document_gpsr_type_options from "utils/select_options/document_gpsr_type_options";

const languages = [
  { id: "document_gpsr_pl", label: "Polski (pl)" },
  { id: "document_gpsr_en", label: "Angielski (en)" },
  { id: "document_gpsr_de", label: "Niemiecki (de)" },
  { id: "document_gpsr_es", label: "Hiszpański (es)" },
  { id: "document_gpsr_fr", label: "Francuski (fr)" },
  { id: "document_gpsr_it", label: "Włoski (it)" },
  { id: "document_gpsr_se", label: "Szwedzki (se)" },
  { id: "document_gpsr_nl", label: "Holenderski (nl)" },
];

const ProductGroupPanel = ({
  data,
  updateProductGroup,
  uploadProductGroupDocument,
}) => {
  const [active_language_to_upload, setActiveLanguageToUpload] = useState(null);
  const [is_updating, setUpdating] = useState(false);

  const handleUploadDocument = async (file) => {
    try {
      await uploadProductGroupDocument({
        product_group_id: data.id,
        name: active_language_to_upload,
        file,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleUpdateProductGroup = async (document_gpsr_type) => {
    try {
      setUpdating(true);
      await updateProductGroup(data.id, { document_gpsr_type });
    } catch (error) {
      console.log(error);
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      {active_language_to_upload && (
        <ModalUpload
          headerText="Wgraj dokument GPSR"
          handleUpload={handleUploadDocument}
          handleClose={() => setActiveLanguageToUpload(null)}
          limit={1}
          accept="application/pdf"
        />
      )}
      <div className="create-table">
        <div className="row p-t-20">
          <div className="box-3">
            <Select
              label="Typ dokumentu"
              options={document_gpsr_type_options}
              defaultValue={document_gpsr_type_options.find(
                ({ value }) => value === data.document_gpsr_type
              )}
              handleChange={handleUpdateProductGroup}
              isDisabled={is_updating}
            />
          </div>
        </div>
        {data.document_gpsr_type && (
          <table>
            <thead>
              <tr>
                <th>Język</th>
                <th>Plik</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {languages.map(({ id, label }) => {
                const file_url = data[`${id}_url`];

                return (
                  <tr key={id}>
                    <td>{label}</td>
                    <td>
                      {file_url ? (
                        <>
                          <a
                            className="text-link"
                            href={file_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Pobierz dokument GPSR
                          </a>
                          <button
                            className="m-l-15 btn btn-blue btn-sm"
                            type="button"
                            onClick={() => setActiveLanguageToUpload(id)}
                          >
                            <span>Aktualizuj</span>
                          </button>
                        </>
                      ) : (
                        <button
                          className="btn btn-blue btn-sm"
                          type="button"
                          onClick={() => setActiveLanguageToUpload(id)}
                        >
                          <span>Dodaj dokument GPSR</span>
                        </button>
                      )}
                    </td>
                    <td />
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};

export default connect(
  ({ product_group: { data } }) => ({
    data,
  }),
  (dispatch) => ({
    uploadProductGroupDocument: (data) =>
      dispatch(uploadProductGroupDocument(data)),
    updateProductGroup: (id, data) => dispatch(updateProductGroup(id, data)),
  })
)(ProductGroupPanel);
